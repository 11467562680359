import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { map } from 'rxjs';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as fromStore from 'src/app/_store/_reducers';
import { SitesService } from 'src/app/_shared/services';
import { HierarchyActions } from 'src/app/_store/_hierarchy/actions';
import { Partner } from 'src/app/_shared/interface/partner.interfacre';

@Injectable()
export class HierarchyEffects {
  initHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyActions.getHierarchyUser.type),
      switchMap((action) => {
        return this.sitesService.getSites().pipe(
          map((sites) => {
            if (sites.partners.length === 1) {
              let demoAccountIndex = sites.partners[0].customers.findIndex((customer) => customer.isDemoAccount === 1);
              if (demoAccountIndex !== -1) {
                let tempAccount = sites.partners[0].customers[demoAccountIndex];
                sites.partners[0].customers.splice(demoAccountIndex, 1);
                sites.partners[0].customers.push(tempAccount);
              }
            }

            return HierarchyActions.getHierarchySuccess({ hierarchy: sites });
          })
        );
      })
    )
  );

  setHierarchyMpSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyActions.setHierarchyMpSelection.type),
      withLatestFrom(this.store.select(fromStore.getHierarchy)),
      switchMap(([action, hierarchy]) => {
        let partners = hierarchy.partners.reduce((acc: Partner[], curr: Partner) => {
          if (curr.accountId === null && curr.customers?.length > 0) {
            acc.push(
              ...curr.customers.map(
                (node): Partner => ({
                  accountId: node.accountId,
                  accountName: node.accountName,
                  customers: [node],
                  subscriptionType: node.subscriptionType,
                  expand: node.expanded,
                })
              )
            );
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

        let accounts;

        if (hierarchy.partners.length === 1 && hierarchy.partners[0].accountId === undefined) {
          accounts = partners[0].customers
            .filter((customer) => {
              return (
                (action.payload.displayBasicAccount || customer?.subscriptionType === 'PREMIUM') &&
                customer.measurementPoints.length > 0
              );
            })
            .map((node) => {
              return { id: node.accountId, name: node.accountName };
            });
        } else {
          accounts = partners
            .filter((partner) => {
              return (
                (action.payload.displayBasicAccount || partner?.subscriptionType === 'PREMIUM') &&
                this.partnerHasMp(partner) &&
                partner.accountId !== null
              );
            })
            .map((node) => {
              return { id: node.accountId, name: node.accountName };
            });
        }

        let isCustomer = false;

        if (action.payload?.accountId && !accounts.find((account) => account.id === action.payload?.accountId)) {
          isCustomer = true;
          partners.forEach((partner) => {
            partner.customers.forEach((customer) => {
              if (customer.accountId === action.payload.accountId) {
                partners = [{ customers: [customer] }];
                accounts = [{ id: customer.accountId, name: customer.accountName }];
                return false;
              }
            });
          });
        }

        return [
          HierarchyActions.setHierarchyMpSelectionSuccess({
            payload: { partners: partners, accounts: accounts, isCustomer: isCustomer },
          }),
        ];
      })
    )
  );

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyActions.addAccountToHierarchy.type),
      withLatestFrom(this.store.select(fromStore.getHierarchy)),
      switchMap(([action, hierarchy]) => {
        let partners: Partner[] = cloneDeep(hierarchy.partners);
        if (action.payload.account.partnerId || action.payload.account.partnerId === null) {
          let accountId: number = action.payload.account.partnerId;
          if (accountId === undefined) accountId = null;

          partners.find((p) => p.accountId === accountId)?.customers.push(action.payload.account);
        } else {
          const startIndex: number = partners.length > 0 ? partners.length - 1 : 0;
          partners.splice(startIndex, 0, action.payload.account);
        }
        return [HierarchyActions.getFilterHierarchySuccess({ hierarchy: { partners } })];
      })
    )
  );

  removeAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyActions.removeAccount.type),
      withLatestFrom(this.store.select(fromStore.getHierarchy)),
      switchMap(([action, hierarchy]) => {
        let partners: Partner[] = cloneDeep(hierarchy.partners);
        let partnerIndex = partners.findIndex((partner) => partner.accountId === action.payload.partnerId);
        let customerIndex = partners[partnerIndex].customers.findIndex(
          (customer) => customer.accountId === action.payload.accountId
        );
        if (customerIndex != -1) {
          partners[partnerIndex].customers.splice(customerIndex, 1);
        }
        return [HierarchyActions.getFilterHierarchySuccess({ hierarchy: { partners } })];
      })
    )
  );

  addMpToHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HierarchyActions.addMpToHierarchy.type),
      withLatestFrom(this.store.select(fromStore.getHierarchy)),
      switchMap(([action, hierarchy]) => {
        let partners: Partner[] = cloneDeep(hierarchy.partners);
        if (action.payload.mp?.length > 0) {
          partners
            .find((p) => p.accountId === action.payload.mp[0].customers[0].partnerId)
            .customers.find((c) => c.accountId === action.payload.mp[0].customers[0].accountId).measurementPoints =
            action.payload.mp[0].customers[0].measurementPoints;
        }
        return [HierarchyActions.getFilterHierarchySuccess({ hierarchy: { partners } })];
      })
    )
  );

  constructor(
    private actions$: Actions<HierarchyActions.HierarchyActionsUnion>,
    private sitesService: SitesService,
    private store: Store<fromStore.State>
  ) {}

  private partnerHasMp(partner): boolean {
    return partner.customers.some((customer) => {
      return customer.measurementPoints.length > 0;
    });
  }
}
