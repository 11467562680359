<div class="release-news-header" data-cy="release-news_header">
  <h1 class="release-news-title">{{ 'news.whats-new' | translate }}</h1>
  @if ((whatsNewFeatureFlag$ | async)?.canManipulate && !editNewsMode) {
    <div class="release-news-action">
      @if ((news$ | async).id !== 0) {
        <button
          mat-icon-button
          data-cy="edit-whats-new"
          type="button"
          class="release-news-action"
          (click)="onEdit(false)"
          [matTooltip]="'news.editTip' | translate"
          >
          <mat-icon color="accent" [svgIcon]="'pencil'"></mat-icon>
        </button>
      }
      <button
        mat-icon-button
        data-cy="add-whats-new"
        type="button"
        class="release-news-action"
        (click)="onEdit(true)"
        [matTooltip]="'news.addTip' | translate"
        >
        <mat-icon color="accent" [svgIcon]="'plus-circle'"></mat-icon>
      </button>
    </div>
  }
  <button mat-icon-button type="button" data-cy="close-what-new" (click)="closeDialog()">
    <mat-icon class="close-button" [svgIcon]="'close'"></mat-icon>
  </button>
</div>
<section class="dialog-container">
  <div>
    @if (!editNewsMode) {
      <div
        class="release-news-content"
        data-cy="release-news_container"
        [innerHTML]="(news$ | async)?.content"
      ></div>
    }
    @if ((whatsNewFeatureFlag$ | async)?.canManipulate && editNewsMode) {
      <form (submit)="onSubmit()" [formGroup]="newsForm">
        <div>
          <editor appEditor [initEditor]="editorConfig" formControlName="newsInformation"></editor>
        </div>
        @if (editNewsMode) {
          <div class="release-news-button">
            <button
              mat-flat-button
              type="button"
              color="accent"
              (click)="onSubmit()"
              [disabled]="newsForm.invalid || !newsForm.dirty"
              data-cy="add-button"
              >
              @if (!addNewsMode) {
                <span>{{ 'global.save' | translate }}</span>
              }
              @if (addNewsMode) {
                <span>{{ 'news.add' | translate }}</span>
              }
            </button>
            <button mat-flat-button type="button" (click)="onCancel()">
              {{ 'global.cancel' | translate }}
            </button>
          </div>
        }
      </form>
    }
    @if (!editNewsMode) {
      <div class="release-news-button">
        <button mat-flat-button type="button" (click)="closeDialog()">
          {{ 'global.close' | translate }}
        </button>
      </div>
    }
  </div>
</section>
