import { Action } from '@ngrx/store';

import { FeaturesActions } from 'src/app/_store/_features/actions';
import {
  BannersFeature,
  CustomerAccountFeature,
  Features,
  NotificationGroupFeature,
  FirmwareUpdateFeature,
  RmsAnalysisFeature,
  ReportCenterFeature,
  FleetFeature,
  ChartFeature,
  HeaderFeature,
  ProfileFeature,
} from 'src/app/_shared/classes/features';

export interface State extends Features {}

const initialState: State = {
  notificationGroup: {
    enabled: false,
    navButtonVisible: false,
    displayBasicAccount: false,
  },
  banners: {
    showExpirationBanner: false,
    showPremiumTrialBanner: false,
  },
  chart: {
    showIncludeDataPriorTo: false,
  },
  customerAccount: {
    subscriptionControl: false,
    canCreatePartner: false,
    canDeleteAccount: false,
    canModify: false,
  },
  firmwareUpdate: {
    enabled: false,
    navButtonVisible: false,
    trendAlarmModel: false,
    promote: false,
    upload: false,
    showPartnerCol: false,
    showAccountCol: false,
  },
  fleet: {
    canCreateAccount: false,
    canCreateMp: false,
  },
  header: {
    whatsNew: {
      canManipulate: false,
    },
    welcome: {
      showActionMsg: false,
    },
  },
  profile: {
    canEditRole: false,
    canSelectNotification: false,
  },
  rmsAnalysis: {
    enabled: false,
    navButtonVisible: false,
  },
  reportCenter: {
    canDeletePastReport: false,
    showCreateReportTab: false,
    showScheduleTab: false,
  },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as FeaturesActions.FeaturesActionsUnion;

  switch (specificAction.type) {
    case FeaturesActions.getFeaturesSuccess.type:
      return {
        ...state,
        ...specificAction.payload,
      };
    case FeaturesActions.setFeatureBannerVisibility.type:
      const banners = { banners: { ...state.banners, ...specificAction.payload } };
      return {
        ...state,
        ...banners,
      };
    default:
      return state;
  }
}

export const getBanners = (state: State): BannersFeature => state.banners;
export const getChart = (state: State): ChartFeature => state.chart;
export const getCustomerAccount = (state: State): CustomerAccountFeature => state.customerAccount;
export const gerReportCenter = (state: State): ReportCenterFeature => state.reportCenter;
export const getRmsAnalysis = (state: State): RmsAnalysisFeature => state.rmsAnalysis;
export const getFleet = (state: State): FleetFeature => state.fleet;
export const getFirmwareUpdate = (state: State): FirmwareUpdateFeature => state.firmwareUpdate;
export const getNotificationGroup = (state: State): NotificationGroupFeature => state.notificationGroup;
export const getHeader = (state: State): HeaderFeature => state.header;
export const getProfile = (state: State): ProfileFeature => state.profile;
