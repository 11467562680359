<section class="dialog-container">
  <div class="site-mp-header" data-cy="site_mp_header">
    <h1 class="site-mp-title">{{ title | translate }} {{ accountName }}</h1>
    <button data-cy="close-button" mat-icon-button type="button" (click)="closeDialog()">
      <mat-icon class="close-button" [svgIcon]="'close'"></mat-icon>
    </button>
  </div>
  <form
    class="site-mp-form"
    data-cy="site_mp_form"
    (submit)="onSubmit($event, parametersTable)"
    [formGroup]="siteMpForm"
  >
    @if (!hideHeader) {
    <div class="site-mp-subheader">
      <div class="subheader-info-container">
        <div class="status-container">
          <div class="status-label">
            {{ 'fleet.table.stage' | translate }}
          </div>
          <div class="status-component">
            @if (isMpStatusEnabled) {
            <div class="status-input">
              <mat-select data-cy="mpstate" [formControl]="mpStatus" (selectionChange)="statusChange($event.value)">
                @for (status of availableStatuses; track status) {
                <mat-option [value]="status">{{ 'measurementPoint.status.' + status | translate }}</mat-option>
                }
              </mat-select>
            </div>
            @if (mpStatus.value === 9 || mpStatus.value === 11) {
            <div>
              <button
                mat-icon-button
                type="button"
                (click)="$event.stopPropagation(); showStateHint(mpStatus.value, false)"
              >
                <mat-icon [svgIcon]="'help-circle'" class="state-hint-icon" color="accent"></mat-icon>
              </button>
            </div>
            }
            <div class="status-commissioned-date">
              @if (siteMp.measurementPoint.measurementPointStatusId === 8) {
              <mat-form-field appearance="fill">
                <mat-label>Commissioning Date</mat-label>
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker"
                  placeholder="Choose a date"
                  [formControl]="commissionedDateCtrl"
                  [min]="minDate"
                  [max]="maxDate"
                  (click)="picker.open()"
                />
                <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [enableMeridian]="enableMeridian"
                  [disableMinute]="false"
                  [hideTime]="false"
                  [touchUi]="false"
                  [color]="color"
                >
                  <ngx-mat-datepicker-actions>
                    <button mat-button ngxMatDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
                  </ngx-mat-datepicker-actions>
                </ngx-mat-datetime-picker>
              </mat-form-field>
              }
            </div>
            } @else {
            <mat-form-field>
              <input
                class="status-input-block"
                matInput
                [formControl]="mpStatus"
                [ngClass]="{
                  'status-pending-installation':
                    siteMp?.measurementPoint.measurementPointStatusId === MpStatusEnum.pendingInstallation
                }"
            /></mat-form-field>
            }
          </div>
        </div>
        @if (!new) {
        <div class="status-container status-container-device">
          <div class="status-label">
            {{ 'manageSiteMp.associate.device' | translate }}
          </div>
          <div class="status-component">
            @if (siteMp?.measurementPoint.measurementPointStatusId === MpStatusEnum.pendingInstallation ||
            siteMp?.measurementPoint.measurementPointStatusId === MpStatusEnum.archived) {
            <span>
              {{ 'manageSiteMp.associate.state.notAssociate' | translate }}
            </span>
            } @else {
            <span>
              {{ 'manageSiteMp.associate.state.associate' | translate }}
            </span>
            } @if ( siteMp?.measurementPoint.measurementPointStatusId === MpStatusEnum.collectingData && viewerMode ===
            false ) {
            <button mat-button class="retry-button" color="accent" (click)="retryAssociation()">
              <mat-icon [svgIcon]="'refresh'"></mat-icon>
              {{ 'manageSiteMp.associate.retry' | translate }}
            </button>
            }
          </div>
        </div>
        }
      </div>
    </div>
    } @if (siteMpForm.controls.street1.touched && (!latitude || !longitude)) {
    <mat-error>
      {{ 'site.invalidAddress' | translate }}
    </mat-error>
    } @if (tabgroup.selectedIndex === getIndexFromTabLabel('site.tabName.info' | translate) && siteMpForm.invalid &&
    siteMpForm.dirty) {
    <mat-error>
      {{ 'site.invalidSiteFormParameters' | translate }}
    </mat-error>
    } @if (tabgroup.selectedIndex === getIndexFromTabLabel('site.tabName.location' | translate) &&
    parametersTable?.invalid) {
    <mat-error>
      {{ 'site.invalidFormParameters' | translate }}
    </mat-error>
    }
    <mat-dialog-content>
      <mat-tab-group #tabgroup dynamicHeight (selectedTabChange)="tabSelection($event)">
        @if (!tabSelectionDisabled?.includes(0)) {
        <mat-tab [label]="'site.tabName.location' | translate">
          <ng-template mat-tab-label>
            <div appEventTracking="mpDialog.location" [trackingParams]="{ mpId: siteMp?.measurementPoint?.roomId }">
              {{ 'site.tabName.location' | translate }}
            </div>
          </ng-template>
          <div class="fields">
            <div class="mp-site-info">
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.name' | translate }}</mat-label>
                  <input matInput formControlName="siteName" data-cy="add-site-name" />
                  @if (siteName.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.shortName' | translate }}</mat-label>
                  <input matInput formControlName="siteShortName" data-cy="add-site-short-name" />
                  @if (siteShortName.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                @if (!isQubescanLogin) {
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.mpType' | translate }}</mat-label>
                  <mat-select formControlName="mpTypeId" required data-cy="select-mpType">
                    <mat-option data-cy="select-QubeScan" [value]="MpTypeEnum.QUBEC_SCAN">
                      {{ 'mp.qubescan' | translate }}
                    </mat-option>
                    <mat-option data-cy="select-DriveScan" [value]="MpTypeEnum.DRIVE_SCAN">
                      {{ 'mp.drivescan' | translate }}
                    </mat-option>
                  </mat-select>
                  @if (mpTypeId.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
                }
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'measurementPoint.name' | translate }}</mat-label>
                  <input matInput formControlName="mpName" data-cy="add-mp-name" />
                  @if (mpName.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  } @if (mpName.hasError('pattern')) {
                  <mat-error>{{ 'global.errors.pattern' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.street1' | translate }}</mat-label>
                  <input type="text" style="display: none" />
                  <input
                    type="text"
                    matInput
                    formControlName="street1"
                    data-cy="add-street1"
                    [matAutocomplete]="auto"
                    #search
                  />
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    @for (address of addresses | async; track address) {
                    <mat-option [value]="address.place_id" (onSelectionChange)="onSelectAddress(address.place_id)">
                      {{ address.description }}
                    </mat-option>
                    }
                  </mat-autocomplete>
                  @if (street1.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.street2' | translate }}</mat-label>
                  <input matInput formControlName="street2" />
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.city' | translate }}</mat-label>
                  <input
                    (mouseenter)="showError = true"
                    matInput
                    formControlName="city"
                    data-cy="add-city"
                    [matAutocomplete]="autoCity"
                    #searchCity
                  />
                  <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
                    @for (address of cityList | async; track address) {
                    <mat-option [value]="address.place_id" (onSelectionChange)="onSelectAddress(address.place_id)">
                      {{ address.description }}
                    </mat-option>
                    }
                  </mat-autocomplete>
                  @if (city.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.state' | translate }}</mat-label>
                  <input matInput formControlName="state" data-cy="add-state" />
                  @if (state.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.zipCode' | translate }}</mat-label>
                  <input matInput formControlName="zipCode" data-cy="add-zipCode" />
                  @if (zipCode.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'site.country' | translate }}</mat-label>
                  <input matInput formControlName="country" data-cy="add-country" />
                  @if (country.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="field map-button">
                <mat-form-field appearance="fill">
                  <mat-label class="gps-label">{{ 'site.gpsCoord' | translate }}</mat-label>
                  <button type="button" mat-icon-button color="accent" (click)="openMap()">
                    <mat-icon [svgIcon]="'map'"></mat-icon>
                  </button>
                  <input
                    matInput
                    formControlName="gps"
                    data-cy="add-gps"
                    pattern="-?[0-9]{1,2}.[0-9]{4,14}, ?-?[0-9]{1,3}.[0-9]{4,14}"
                    (focusout)="getAddress()"
                  />
                  @if (gps.hasError('required')) {
                  <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
                  } @if (gps.hasError('pattern')) {
                  <mat-error class="gps-error">{{ 'site.errors.gps' | translate }}</mat-error>
                  }
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
        } @if (!new && !tabSelectionDisabled?.includes(1)) {
        <mat-tab [label]="'site.tabName.info' | translate">
          <ng-template mat-tab-label>
            <div appEventTracking="mpDialog.info" [trackingParams]="{ mpId: siteMp?.measurementPoint?.roomId }">
              {{ 'site.tabName.info' | translate }}
            </div>
          </ng-template>
          <div
            class="site-image"
            [ngClass]="!imgReady ? 'disabled' : ''"
            (mouseover)="hideRemoveImage = false"
            (mouseleave)="hideRemoveImage = true"
          >
            <img [src]="mpImgUrl" width="100%" data-cy="nameplate-image" />
            @if (!mpImgId) {
            <app-drag-drop-zone
              [disabled]="!canModify"
              [viewerMode]="viewerMode"
              class="site-image-drag-drop"
              resourceLabel="image"
              (fileDownload)="fileDownload($event)"
            ></app-drag-drop-zone>
            } @if (canModify) {
            <button
              mat-icon-button
              [ngStyle]="(hideRemoveImage || mpImgId === null) && { display: 'none' }"
              matTooltip="{{ 'manageSiteMp.image.removeImage' | translate }}"
              (click)="$event.preventDefault(); removeMainImage()"
              class="site-image-remove"
              data-cy="nameplate-delete"
            >
              <mat-icon [svgIcon]="'delete-circle-plain'"></mat-icon>
            </button>
            }
          </div>
          @if (!imgReady) {
          <div class="site-image-loading">
            <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
          </div>
          }
        </mat-tab>
        } @if (!new && !tabSelectionDisabled?.includes(2)) {
        <mat-tab [label]="'site.tabName.parameters' | translate">
          <ng-template mat-tab-label>
            <div appEventTracking="mpDialog.parameters" [trackingParams]="{ mpId: siteMp?.measurementPoint?.roomId }">
              {{ 'site.tabName.parameters' | translate }}
            </div>
          </ng-template>
          <app-site-mp-parameters-table
            #parameters
            [mpId]="siteMp?.measurementPoint?.roomId"
            [mpTypeId]="siteMp?.measurementPoint?.measurementPointTypeId"
            [viewerMode]="viewerMode"
            [status]="mpStatus.value"
            [defaultEditParameterName]="defaultEditParameterName"
          ></app-site-mp-parameters-table>
        </mat-tab>
        } @if (accessTabEnabled$ | async) {
        <mat-tab [label]="'site.tabName.access' | translate">
          <ng-template mat-tab-label>
            <div appEventTracking="mpDialog.access" [trackingParams]="{ mpId: siteMp?.measurementPoint?.roomId }">
              {{ 'site.tabName.access' | translate }}
            </div>
          </ng-template>
          <app-mp-access
            [mpId]="siteMp.measurementPoint.roomId"
            (accessChangeEvent)="receiveAccessChange($event)"
          ></app-mp-access>
        </mat-tab>
        } @if (!new && siteMp?.measurementPoint?.measurementPointStatusId === 8 && !tabSelectionDisabled?.includes(3)) {
        <mat-tab [disabled]="(!isAdmin || viewerMode) && !isSystemAdmin" [label]="'site.tabName.moveMP' | translate">
          <ng-template mat-tab-label>
            <div appEventTracking="mpDialog.move" [trackingParams]="{ mpId: siteMp?.measurementPoint?.roomId }">
              {{ 'site.tabName.moveMP' | translate }}
            </div>
          </ng-template>
          <app-move-to [mpId]="siteMp?.measurementPoint?.roomId" (closeDialog)="closeDialog()"></app-move-to>
        </mat-tab>
        } @if ( !new && (siteMp?.measurementPoint?.measurementPointStatusId === 8 || step?.steps.length > 0) &&
        siteMp?.measurementPoint?.measurementPointStatusId !== 9 && !tabSelectionDisabled?.includes(4)) {
        <mat-tab
          [disabled]="(!isAdmin || viewerMode) && !isSystemAdmin"
          data-cy="replace-device"
          [label]="'site.tabName.replaceDevice' | translate"
        >
          <ng-template mat-tab-label>
            <div appEventTracking="mpDialog.replace" [trackingParams]="{ mpId: siteMp?.measurementPoint?.roomId }">
              {{ 'site.tabName.replaceDevice' | translate }}
            </div>
          </ng-template>
          <app-replace-pqube
            [mpId]="siteMp?.measurementPoint?.roomId"
            [step]="step"
            [mp]="mp"
            (disassociateEvent)="disassociateEvent()"
            (associateEvent)="associateEvent()"
            (updateStep)="updateStep($event)"
          ></app-replace-pqube>
        </mat-tab>
        }
      </mat-tab-group>
      @if (siteMp?.measurementPoint?.measurementPointTypeId !== MpTypeEnum.IN_SITE && !new) {
      <div
        class="site-info-field"
        [ngStyle]="{
          display:
            tabgroup && tabgroup.selectedIndex === getIndexFromTabLabel('site.tabName.info' | translate)
              ? 'inherit'
              : 'none'
        }"
      >
        <h4>{{ 'site.mpTag' | translate }}</h4>
        <div class="mp-tag">
          <mat-form-field appearance="fill">
            <input matInput formControlName="mpTag" data-cy="add-mp-tag" maxlength="128" />
          </mat-form-field>
        </div>
        <h4>{{ 'site.siteInformation' | translate }}</h4>
        <div class="site-info-editor">
          <editor
            appEditor
            [imagesList]="availablesImagesObservable"
            [fileUploadGetNameCallback]="saveImageToServerResolveFileName"
            [fileUploadCallback]="saveImageToServer"
            [filePickerCallback]="saveImageToServer"
            [readonly]="siteMpForm.controls.siteInformation.disabled"
            [initEditor]="editorConfig"
            formControlName="siteInformation"
          ></editor>
        </div>
      </div>
      } @if (!new && (isSystemAdmin || (isAdmin && !viewerMode)) && !hideActions) {
      <div
        class="site-info-field"
        [ngStyle]="{
          display:
            tabgroup && tabgroup.selectedIndex === getIndexFromTabLabel('site.tabName.location' | translate)
              ? 'inherit'
              : 'none'
        }"
      >
        <h4>{{ 'site.actions' | translate }}</h4>
        <div>
          @if(isSystemAdmin){
          <button
            mat-button
            type="button"
            color="accent"
            class="unassociate-button"
            [disabled]="
              siteMp?.measurementPoint.measurementPointStatusId === MpStatusEnum.pendingInstallation ||
              siteMp?.measurementPoint.measurementPointStatusId === MpStatusEnum.archived
            "
            (click)="unassociateMp($event)"
            data-cy="disassociate"
          >
            <mat-icon [svgIcon]="'link-variant-off'"></mat-icon>
            {{ 'site.dis-associatePqube' | translate }}
          </button>
          }
          <button
            mat-button
            type="button"
            color="accent"
            class="unassociate-button"
            [disabled]="
              isMpStatusEnabled &&
              siteMp?.measurementPoint.measurementPointStatusId !== MpStatusEnum.archived &&
              siteMp?.measurementPoint.measurementPointStatusId !== MpStatusEnum.pendingInstallation
            "
            (click)="deleteMp($event)"
          >
            <mat-icon [svgIcon]="'delete'"></mat-icon> {{ 'site.deleteMp' | translate }}
          </button>
        </div>
      </div>
      } @if (tabgroup.selectedIndex!== getIndexFromTabLabel('site.tabName.replaceDevice' | translate) &&
      tabgroup.selectedIndex !== getIndexFromTabLabel('site.tabName.moveMP' | translate) || (tabgroup.selectedIndex ===
      getIndexFromTabLabel('site.tabName.moveMP' | translate) && (accessTabEnabled$ | async))) {
      <div class="site-mp-button" (mouseenter)="siteEnterEvent()">
        @if ( tabgroup.selectedIndex !== getIndexFromTabLabel('site.tabName.parameters' | translate) || siteMpForm.dirty
        || commissionedDateCtrl.dirty || mpImgUrl !== mpImgInititalUrl ) {
        <button
          mat-flat-button
          data-cy="add-submit"
          color="accent"
          type="submit"
          [disabled]="
            ((siteMpForm.invalid ||
              (!siteMpForm.dirty && !parametersTable?.valueChanged) ||
              parametersTable?.invalid) &&
              (commissionedDateCtrl === undefined || (commissionedDateCtrl && !commissionedDateCtrl.dirty)) &&
              mpImgUrl === mpImgInititalUrl &&
              !mpStatus.dirty) ||
            (siteMpForm.controls.street1.touched && (!latitude || !longitude))
          "
        >
          @if (!new) {
          <span>{{ 'global.save' | translate }}</span>
          } @if (new) {
          <span>{{ 'global.create' | translate }}</span>
          }
        </button>
        } @if ( tabgroup.selectedIndex === getIndexFromTabLabel('site.tabName.parameters' | translate) &&
        !siteMpForm.dirty && !commissionedDateCtrl.dirty && mpImgUrl === mpImgInititalUrl ) {
        <button
          mat-flat-button
          color="accent"
          type="button"
          (click)="onSubmit($event, parametersTable)"
          [disabled]="!parametersTable?.valueChanged || parametersTable?.invalid"
        >
          @if (!new) {
          <span>{{ 'global.save' | translate }}</span>
          } @if (new) {
          <span>{{ 'global.create' | translate }}</span>
          }
        </button>
        }
        <button mat-flat-button type="button" (click)="closeDialog()" data-cy="close">
          {{ 'global.cancel' | translate }}
        </button>
      </div>
      }
    </mat-dialog-content>
  </form>
</section>
