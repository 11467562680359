<section class="dialog-container">
  <div class="welcome-header">
    <h1 mat-dialog-title>
      {{ 'welcome.title' | translate: { firstName: firstName } }}
    </h1>
    <button mat-icon-button type="button" (click)="closeDialog()">
      <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
  </div>
  @if ((welcomeFeatureFlag$ | async).showActionMsg) {
    <div>
      <p [innerHTML]="'welcome.adminEngineer.message' | translate"></p>
      <div class="bullet-point">
        <p>
          <span class="bullet-header">A.&nbsp;&nbsp;</span
            ><span [innerHTML]="'welcome.adminEngineer.point.a' | translate"></span>
          </p>
          <p>
            <span class="bullet-header">B.&nbsp;&nbsp;</span><mat-icon svgIcon="add-mp" color="accent"></mat-icon>
            {{ 'welcome.adminEngineer.point.b' | translate }}
          </p>
          <p>
            <span class="bullet-header">C.&nbsp;&nbsp;</span><mat-icon svgIcon="link-variant" color="accent"></mat-icon>
            {{ 'welcome.adminEngineer.point.c' | translate }}
          </p>
        </div>
      </div>
    } @else {
      <p [innerHTML]="'welcome.viewer.message' | translate"></p>
    }
    <div class="welcome-close-button">
      <button mat-flat-button type="button" (click)="closeDialog()" data-cy="welcome-close">
        {{ 'global.close' | translate }}
      </button>
    </div>
  </section>
  