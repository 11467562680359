import { Action } from '@ngrx/store';

import { HierarchyActions } from 'src/app/_store/_hierarchy/actions';
import { Hierarchy } from 'src/app/_shared/interface/hierarchy.interface';
import { Partner } from 'src/app/_shared/interface/partner.interfacre';
import { MpSelectionAccount } from 'src/app/_shared/interface/mp-selection-account.interface';

export interface State {
  hierarchy: Hierarchy;
  filteredHierarchy: Hierarchy;
  mpSelection: {
    partners: Array<Partner>;
    accounts: Array<MpSelectionAccount>;
    isCustomer: boolean;
  };
}

const initialState: State = {
  hierarchy: { partners: [] },
  filteredHierarchy: { partners: [] },
  mpSelection: {
    partners: null,
    accounts: null,
    isCustomer: null,
  },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as HierarchyActions.HierarchyActionsUnion;

  switch (specificAction.type) {
    case HierarchyActions.getHierarchySuccess.type:
      return {
        ...state,
        hierarchy: specificAction.hierarchy,
      };
    case HierarchyActions.getFilterHierarchySuccess.type:
      return {
        ...state,
        filteredHierarchy: specificAction.hierarchy,
      };
    case HierarchyActions.setHierarchyMpSelectionSuccess.type:
      return {
        ...state,
        mpSelection: {
          partners: specificAction.payload.partners,
          accounts: specificAction.payload.accounts,
          isCustomer: specificAction.payload.isCustomer,
        },
      };
    case HierarchyActions.resetHierarchyMpSelection.type:
      return {
        ...state,
        mpSelection: {
          partners: null,
          accounts: null,
          isCustomer: null,
        },
      };
    case HierarchyActions.resetFilterHierarchy.type:
      return {
        ...state,
        filteredHierarchy: { partners: [] },
      };

    default:
      return state;
  }
}

export const getHierarchy = (state: State): State['hierarchy'] => state.hierarchy;
export const getPartners = (state: State): Hierarchy['partners'] => state.hierarchy.partners;
export const getFilterPartners = (state: State): Hierarchy['partners'] => state.filteredHierarchy.partners;
export const getHierarchyMpSelection = (state: State): State['mpSelection'] => state.mpSelection;
